@import './fonts/Roboto/Roboto.css';
@import './fonts/NanumPenScript/NanumPenScript.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
}

main {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code,
pre,
code > *,
code > * > *,
code > * > * > * {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace !important;
  font-size: 12px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.roboto-black {
  font-family: 'Roboto-Black';
}

.roboto-bold {
  font-family: 'Roboto-Bold';
}

.roboto-medium {
  font-family: 'Roboto-Medium';
}

.roboto-regular {
  font-family: 'Roboto-Regular';
}

.handwriting {
  font-family: 'NanumPenScript' !important;
  letter-spacing: 0.05em;
}

.roboto-light {
  font-family: 'Roboto-Light';
}

h1 {
  font-family: 'Roboto-Black';
}

h2 {
  font-family: 'Roboto-Bold';
}

h3 {
  font-family: 'Roboto-Medium';
}

span,
button {
  font-family: 'Roboto-Regular';
}

p,
li {
  font-family: 'Roboto-Light';
}

.mobileDropdown {
  z-index: 10;
  height: 100%;
}

nav {
  z-index: 20;
  overflow: hidden;
}

.navContent,
main {
  max-width: 1200px;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.active {
  position: relative;
}

.active::before {
  content: '';
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 10px solid #5046e5;
  border-radius: 5px;
}

.componentsWrapper::-webkit-scrollbar,
.App::-webkit-scrollbar {
  display: none;
}

.App {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.rippleButton {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0 5px;

  position: relative;
  background-color: #f1f1f118;
}

.rippleButton::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  right: -1px;
  background-color: inherit;
  border-radius: 50%;
  z-index: -1;
  animation: ripple 1.5s ease-out infinite;
  background-color: #5e41ffcb;
}
@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

.docWrapper > p {
  font-family: 'Roboto-Regular';
}

.docWrapper > h2 {
  font-size: 1.2em;
}

.docWrapper {
  margin-bottom: 20vh;
}

.colorPreviewDocs {
  display: flex;
  flex-wrap: wrap;
}

.colorPreviewDocs > * {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 20%;
}

.componentWrappingBox {
  padding-top: min(50px);
  padding-bottom: min(50px);
}

.text-shadow-md {
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}

pre {
  border-radius: 0 0 10px 10px !important;

  padding: 10px 0 0 0 !important;
  overflow: hidden !important;
}

pre > ::-webkit-scrollbar {
  height: 12px;
}

pre > ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

pre > ::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 10px;
}

pre > ::-webkit-scrollbar-thumb:hover {
  background: #d3d3d3;
}

.border_red {
  border: 1px solid red;
}

.border_green {
  border: 1px solid green;
}
